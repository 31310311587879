// components/CustomImage.tsx
import React from 'react';
import Image, { ImageProps } from 'next/image';

interface WsImageProps extends ImageProps {
  alt: string;
}

const WsFillImage: React.FC<WsImageProps> = ({ src, alt, ...props }) => {
  return (
    <Image
      src={src}
      alt={alt}
      {...props}
      sizes="100vw"
      quality={100}
      fill={true}
      style={{ height: '100%', width: '100%' }}
    />
  );
};

export default WsFillImage;

import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { getRequestedSettings } from '@/store/features/commonSlice';
import { getSettingByKeyValue, updateImageVersion } from '@/utils/common';
import WsFillImage from '@/components/common/WsFillImage';

interface BannerProps {
  data: any;
}
const Banner: React.FC<BannerProps> = ({ data = {} }) => {
  const imageBaseS3Url = process.env.NEXT_PUBLIC_IMAGE_S3_BUCKET_BASE_URL;
  const requestedSettings = useSelector(getRequestedSettings);
  const imgVer = getSettingByKeyValue(requestedSettings, 'config_imgvrsn');
  data.items = updateImageVersion(data?.items, 'v', imgVer);
  return (
    <>
      <section className={styles['banner-section']}>
        <div className={styles['banner-inner']}>
          <div className="home-container-1600 p-0">
            <div className={styles['banner-content']}>
              <div className={styles['banner-left']}>
                <div className={styles['banner-slider']}>
                  <div className="bannerswiperslider">
                    <Swiper
                      navigation={true}
                      loop={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {data?.items?.map((banner: any, index: number) => (
                        <SwiperSlide key={index}>
                          <Link href={banner.href}>
                            <figure>
                              <WsFillImage
                                loading="eager"
                                priority
                                alt={banner.altText}
                                src={banner.imgSrc}
                              />
                            </figure>
                          </Link>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
              <div className={styles['banner-right']}>
                <div className="wdRow wdgy-2">
                  <div className="wdCol-lg-12 wdCol-md-12 wdCol-sm-6">
                    <div className={styles['banner-img']}>
                      <Link href="/deal-of-the-day">
                        <figure>
                          <WsFillImage
                            src={`${imageBaseS3Url}/images/home-new1/diwali-special-banner.jpg?v=${imgVer}`}
                            alt="buy wooden furniture in India"
                            loading="eager"
                          />
                        </figure>
                      </Link>
                    </div>
                  </div>
                  <div className="wdCol-lg-12 wdCol-md-12 wdCol-sm-6">
                    <div className={styles['banner-img']}>
                      <Link href="/sofa-cum-beds">
                        <figure>
                          <Image
                            src={`${imageBaseS3Url}/images/home-new1/Sofa-Cum-Beds-Gif.gif?v=${imgVer}`}
                            height={312}
                            width={624}
                            sizes="100vw"
                            unoptimized
                            alt="wooden home furniture online"
                            priority
                            loading="eager"
                          />
                        </figure>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.discountsection}>
        <div className={styles.discountinner}>
          <div className="home-container-1600">
            <div className={styles.discountimg}>
              <Image
                src={`${imageBaseS3Url}/images/home-new1/emi-banner.jpg?v=${imgVer}`}
                alt="Emi Installation"
                width={'1600'}
                height={'142'}
                quality={100}
                sizes="100vw"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
